
import { defineComponent, ref } from "vue";
import { requiredField } from "@/util/form-rules";
import FormLoading from "../../layout/FormLoading.vue";
import useForm from "@/modules/useForm";
import Duvida from "@/interfaces/Duvida";
import BaseService from "@/services/admin/BaseService";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import router from "@/router";

interface Form {
  texto: string;
}

export default defineComponent({
  components: { FormLoading },
  props: {
    isAluno: { default: false },
  },
  setup(props, { emit }) {
    const $q = useQuasar();
    const duvida = ref<Duvida>();
    const form = ref<Form>({ texto: "" });
    const urlImg = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const { show, loading } = useForm(
      "duvidas",
      "duvidas",
      "a",
      emit,
      "duvidas"
    );

    const open = (data: Duvida | null): void => {
      duvida.value = JSON.parse(JSON.stringify(data));
      show.value = true;
    };

    const addComentario = (): void => {
      loading.value = true;
      BaseService.store("duvidas/comentario", {
        texto: form.value.texto,
        duvida_id: duvida.value?.id,
      })
        .then((res) => {
          emit("update");
          form.value = { texto: "" };
          show.value = false;
          showNotify({
            type: "positive",
            message: "Resposta enviada com sucesso",
          });
        })
        .catch((err) => {
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const marcarResolvida = (): void => {
      $q.dialog({
        title: "Marcar como Resolvida",
        message: "Deseja realmente marcar essa dúvida como resolvida?",
        cancel: "Cancelar",
        persistent: true,
      }).onOk(() => {
        if (duvida.value) {
          BaseService.list(`duvidas/resolver/${duvida.value.id}`)
            .then(() => {
              emit("update");
              form.value = { texto: "" };
              show.value = false;
              showNotify({
                type: "positive",
                message: "Dúvida marcada como resolvida",
              });
            })
            .catch((err) => {
              console.log(err);
              showNotify({
                type: "negative",
                message: "Erro ao alterar status da dúvida",
              });
            })
            .finally(() => (loading.value = false));
        }
      });
    };

    const goToAula = (): void => {
      if (duvida.value) {
        router.push({
          name: "ver-aula",
          params: {
            id: duvida.value.aula?.assunto?.disciplina?.id,
            aulaRouterId: duvida.value.aula?.id,
          },
        });
      }
    };

    const formatData = (data: string): string => {
      return `${data.substr(8, 2)}/${data.substr(5, 2)}/${data.substr(0, 4)}`;
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    return {
      form,
      show,
      open,
      duvida,
      requiredField,
      loading,
      formatData,
      addComentario,
      marcarResolvida,
      urlImg,
      goToAula,
    };
  },
});
